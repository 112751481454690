import { MasterTableClient, RoleClient } from "./ohl.virtualCenter.api";

const apiClient = new MasterTableClient(process.env.REACT_APP_PUBLIC_API);
const apiRoleClient = new RoleClient(process.env.REACT_APP_PUBLIC_API);
console.log('Using ' + process.env.REACT_APP_PUBLIC_API + ' route', process.env);

// DONE ----------------------
// provinces
// countries
// languajes
// likes

// client.type
// contact.type
// communication.device.type
// workingcenter
// activity.type
// professional.type
//
// TODO ----------------------
// monitoring.level
// activity.alert.criticality.type
// communication.company
// physical.status
// administration.type
// physical.cognitive.status
// activity.alert.type
// user.history.type

let provinces = null;
let provincesPromise = null;

let countries = null;
let countriesPromise = null;

let languajes = null;
let languajesPromise = null;

let clientLikes = null;
let clientLikesPromise = null;

let clientType = null;
let clientTypePromise = null;

let professionalType = null;
let professionalTypePromise = null;

let contactType = null;
let contactTypePromise = null;

let communicationDeviceType = null;
let communicationDeviceTypePromise = null;

let workingcenter = null;
let workingcenterPromise = null;

let gender = null;
let genderPromise = null;

let dependencyDegree = null;
let dependencyDegreePromise = null;

let therapeuticProfile = null;
let therapeuticProfilePromise = null;

let technologicalIndependence = null;
let technologicalIndependencePromise = null;

let usesTechHelp = null;
let usesTechHelpPromise = null;

let activityType = null;
let activityTypePromise = null;

let professionalRoles = null;
let professionalRolesPromise = null;

let baseportLocations = null;
let baseportLocationsPromise = null;

let aerialDeviceTypes = null;
let aerialDeviceTypesPromise = null;

let aerialResidentPetSize = null;
let aerialResidentPetSizePromise = null;

let aerialResidenceType = null;
let aerialResidenceTypePromise = null;

let civilStatus = null;
let civilStatusPromise = null;

let profession = null;
let professionPromise = null;

export const getProvinces = () => {
    if (provinces) return new Promise((resolve, reject) => {
        resolve(provinces);
    });
    if (provincesPromise) return provincesPromise

    provincesPromise = new Promise((resolve, reject) => {
        apiClient.single({
            "type": "provinces",
            "subType": "108"
        })
            .then(
                res => {
                    provinces = res;
                    resolve(res);
                }
            ).catch(
                err => {
                    reject(err);
                }
            )
    })
    return provincesPromise;
}

export const getProvincesFilter = () => {
    if (provinces) return new Promise((resolve, reject) => {
        resolve(provinces);
    });
    if (provincesPromise) return provincesPromise

    provincesPromise = new Promise((resolve, reject) => {
        apiClient.single({
            "type": "provinces",
            "subType": "108"
        })
            .then(
                res => {
                    provinces = res;
                    resolve(res);
                }
            ).catch(
                err => {
                    reject(err);
                }
            )
    })
    return provincesPromise;
}


export const getCountries = () => {
    if (countries) return new Promise((resolve, reject) => {
        resolve(countries);
    });
    if (countriesPromise) return countriesPromise

    countriesPromise = new Promise((resolve, reject) => {
        apiClient.single({
            "type": "countries"
        })
            .then(
                res => {
                    countries = res;
                    resolve(res);
                }
            ).catch(
                err => {
                    reject(err);
                }
            )
    })
    return countriesPromise
}

export const getLanguajes = () => {
    if (languajes) return new Promise((resolve, reject) => {
        resolve(languajes);
    });
    if (languajesPromise) return languajesPromise

    languajesPromise = new Promise((resolve, reject) => {
        apiClient.single({
            "type": "languajes"
        })
            .then(
                res => {
                    languajes = res;
                    resolve(res);
                }
            ).catch(
                err => {
                    reject(err);
                }
            )
    })
    return languajesPromise
}

export const getLikes = () => {
    if (clientLikes) return new Promise((resolve, reject) => {
        resolve(clientLikes);
    });
    if (clientLikesPromise) return clientLikesPromise

    clientLikesPromise = new Promise((resolve, reject) => {
        apiClient.single({
            "type": "client.likes"
        })
            .then(
                res => {
                    clientLikes = res;
                    resolve(res);
                }
            ).catch(
                err => {
                    reject(err);
                }
            )
    })
    return clientLikesPromise
}

export const getClientType = () => {
    if (clientType) return new Promise((resolve, reject) => {
        resolve(clientType);
    });
    if (clientTypePromise) return clientTypePromise

    clientTypePromise = new Promise((resolve, reject) => {
        apiClient.single({
            "type": "client.type"
        })
            .then(
                res => {
                    clientType = res;
                    resolve(res);
                }
            ).catch(
                err => {
                    reject(err);
                }
            )
    })
    return clientTypePromise
}

export const getProfessionalType = () => {
    if (professionalType) return new Promise((resolve, reject) => {
        resolve(professionalType);
    });
    if (professionalTypePromise) return professionalTypePromise

    professionalTypePromise = new Promise((resolve, reject) => {
        apiClient.single({
            "type": "professional.type"
        })
            .then(
                res => {
                    professionalType = res;
                    resolve(res);
                }
            ).catch(
                err => {
                    reject(err);
                }
            )
    })
    return professionalTypePromise
}

export const getContactType = () => {
    if (contactType) return new Promise((resolve, reject) => {
        resolve(contactType);
    });
    if (contactTypePromise) return contactTypePromise

    contactTypePromise = new Promise((resolve, reject) => {
        apiClient.single({
            "type": "contact.type"
        })
            .then(
                res => {
                    contactType = res;
                    resolve(res);
                }
            ).catch(
                err => {
                    reject(err);
                }
            )
    })
    return contactTypePromise
}

export const getComunicationDeviceType = () => {
    if (communicationDeviceType) return new Promise((resolve, reject) => {
        resolve(communicationDeviceType);
    });
    if (communicationDeviceTypePromise) return communicationDeviceTypePromise

    communicationDeviceTypePromise = new Promise((resolve, reject) => {
        apiClient.single({
            "type": "communication.device.type"
        })
            .then(
                res => {
                    communicationDeviceType = res;
                    resolve(res);
                }
            ).catch(
                err => {
                    reject(err);
                }
            )
    })
    return communicationDeviceTypePromise;
}

export const getWorkingCenter = () => {
    if (workingcenter) return new Promise((resolve, reject) => {
        resolve(workingcenter);
    });
    if (workingcenterPromise) return workingcenterPromise

    workingcenterPromise = new Promise((resolve, reject) => {
        apiClient.single({
            "type": "workingcenter"
        })
            .then(
                res => {
                    workingcenter = res;
                    resolve(res);
                }
            ).catch(
                err => {
                    reject(err);
                }
            )
    })
    return workingcenterPromise
}

export const getGender = () => {
    if (gender) return new Promise((resolve, reject) => {
        resolve(gender);
    });
    if (genderPromise) return genderPromise

    genderPromise = new Promise((resolve, reject) => {
        apiClient.single({
            "type": "client.gender"
        })
            .then(
                res => {
                    gender = res;
                    resolve(res);
                }
            ).catch(
                err => {
                    reject(err);
                }
            )
    })
    return genderPromise
}

export const getDependencyDegree = () => {
    if (dependencyDegree) return new Promise((resolve, reject) => {
        resolve(dependencyDegree);
    });
    if (dependencyDegreePromise) return dependencyDegreePromise

    dependencyDegreePromise = new Promise((resolve, reject) => {
        apiClient.single({
            "type": "client.dependencyDegree"
        })
            .then(
                res => {
                    dependencyDegree = res;
                    resolve(res);
                }
            ).catch(
                err => {
                    reject(err);
                }
            )
    })
    return dependencyDegreePromise
}

export const getTherapeuticProfile = () => {
    if (therapeuticProfile) return new Promise((resolve, reject) => {
        resolve(therapeuticProfile);
    });
    if (therapeuticProfilePromise) return therapeuticProfilePromise

    therapeuticProfilePromise = new Promise((resolve, reject) => {
        apiClient.single({
            "type": "client.therapeuticProfile"
        })
            .then(
                res => {
                    therapeuticProfile = res;
                    resolve(res);
                }
            ).catch(
                err => {
                    reject(err);
                }
            )
    })
    return therapeuticProfilePromise
}

export const getTechnologicalIndependence = () => {
    if (technologicalIndependence) return new Promise((resolve, reject) => {
        resolve(technologicalIndependence);
    });
    if (technologicalIndependencePromise) return technologicalIndependencePromise

    technologicalIndependencePromise = new Promise((resolve, reject) => {
        apiClient.single({
            "type": "client.technologicalIndependence"
        })
            .then(
                res => {
                    technologicalIndependence = res;
                    resolve(res);
                }
            ).catch(
                err => {
                    reject(err);
                }
            )
    })
    return technologicalIndependencePromise
}

export const getUsesTechHelp = () => {
    if (usesTechHelp) return new Promise((resolve, reject) => {
        resolve(usesTechHelp);
    });
    if (usesTechHelpPromise) return usesTechHelpPromise

    usesTechHelpPromise = new Promise((resolve, reject) => {
        apiClient.single({
            "type": "client.usesTechHelp"
        })
            .then(
                res => {
                    usesTechHelp = res;
                    resolve(res);
                }
            ).catch(
                err => {
                    reject(err);
                }
            )
    })
    return usesTechHelpPromise
}

export const getActivityType = () => {
    if (activityType) return new Promise((resolve, reject) => {
        resolve(activityType);
    });
    if (activityTypePromise) return activityTypePromise

    activityTypePromise = new Promise((resolve, reject) => {
        apiClient.single({
            "type": "activity.type"
        })
            .then(
                res => {
                    activityType = res;
                    resolve(res);
                }
            ).catch(
                err => {
                    reject(err);
                }
            )
    })
    return activityTypePromise;
}

export const getActivityTypeValue = (subtype) => {
    return apiClient.single({
        "type": "activity.typeValue",
        "subType": subtype
    })
}

export const getProfessionalRoles = () => {
    if (professionalRoles) return new Promise((resolve, reject) => {
        resolve(professionalRoles);
    });
    if (professionalRolesPromise) return professionalRolesPromise

    professionalRolesPromise = new Promise((resolve, reject) => {
        apiRoleClient.professionals()
            .then(
                res => {
                    professionalRoles = res;
                    resolve(res);
                }
            ).catch(
                err => {
                    reject(err);
                }
            )
    })
    return professionalRolesPromise
}

export const getBaseportLocations = () => {
    if (baseportLocations) return new Promise((resolve, reject) => {
        resolve(baseportLocations);
    });
    if (baseportLocationsPromise) return baseportLocationsPromise

    baseportLocationsPromise = new Promise((resolve, reject) => {
        apiClient.single({
            "type": "aerial.location"
        })
            .then(
                res => {
                    baseportLocations = res;
                    resolve(res);
                }
            ).catch(
                err => {
                    reject(err);
                }
            )
    })
    return baseportLocationsPromise;
}

export const getAerialDeviceTypes = () => {
    if (aerialDeviceTypes) return new Promise((resolve, reject) => {
        resolve(aerialDeviceTypes);
    });
    if (aerialDeviceTypesPromise) return aerialDeviceTypesPromise

    aerialDeviceTypesPromise = new Promise((resolve, reject) => {
        apiClient.single({
            "type": "aerial.deviceType"
        })
            .then(
                res => {
                    aerialDeviceTypes = res;
                    resolve(res);
                }
            ).catch(
                err => {
                    reject(err);
                }
            )
    })
    return aerialDeviceTypesPromise;
}

export const getAerialResidentPetSize = () => {
    if (aerialResidentPetSize) return new Promise((resolve, reject) => {
        resolve(aerialResidentPetSize);
    });
    if (aerialResidentPetSizePromise) return aerialResidentPetSizePromise

    aerialResidentPetSizePromise = new Promise((resolve, reject) => {
        apiClient.single({
            "type": "aerial.residentPetSize"
        })
            .then(
                res => {
                    aerialResidentPetSize = res;
                    resolve(res);
                }
            ).catch(
                err => {
                    reject(err);
                }
            )
    })
    return aerialResidentPetSizePromise;
}

export const getAerialResidenceType = () => {
    if (aerialResidenceType) return new Promise((resolve, reject) => {
        resolve(aerialResidenceType);
    });
    if (aerialResidenceTypePromise) return aerialResidenceTypePromise

    aerialResidenceTypePromise = new Promise((resolve, reject) => {
        apiClient.single({
            "type": "aerial.residenceType"
        })
            .then(
                res => {
                    aerialResidenceType = res;
                    resolve(res);
                }
            ).catch(
                err => {
                    reject(err);
                }
            )
    })
    return aerialResidenceTypePromise;
}



export const getCivilStatus = () => {
    if (civilStatus) return new Promise((resolve, reject) => {
        resolve(civilStatus);
    });
    if (civilStatusPromise) return civilStatusPromise

    civilStatusPromise = new Promise((resolve, reject) => {
        apiClient.single({
            "type": "client.civilStatus"
        })
            .then(
                res => {
                    civilStatus = res;
                    resolve(res);
                }
            ).catch(
                err => {
                    reject(err);
                }
            )
    })
    return civilStatusPromise
}

export const getProfession = () => {
    if (profession) return new Promise((resolve, reject) => {
        resolve(profession);
    });
    if (professionPromise) return professionPromise

    professionPromise = new Promise((resolve, reject) => {
        apiClient.single({
            "type": "client.profession"
        })
            .then(
                res => {
                    profession = res;
                    resolve(res);
                }
            ).catch(
                err => {
                    reject(err);
                }
            )
    })
    return professionPromise
}


export const resetCache = (masterTableTypeArray) => {
    masterTableTypeArray.forEach(element => {
        console.log('Clear cache ' + element)
        switch (element) {
            case 'client.likes':
                clientLikes = null;
                clientLikesPromise = null;
                break;
            case 'workingcenter':
                workingcenter = null;
                workingcenterPromise = null;
                break;

            default:
                break;
        }
    });
}