import { ProfessionalClient } from "../services/ohl.virtualCenter.api";

const apiClient = new ProfessionalClient(process.env.REACT_APP_PUBLIC_API);

class ProfessionalProvider {

    getList = (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        return apiClient.list({
            pageNumber: page,
            pageSize: perPage,
            orderField: field,
            orderAsc: order === 'ASC',
            ...params.filter
        }).then((data) => {
            return {
                data: data.elements,
                // total: parseInt(headers.get('content-range').split('/').pop(), 10),
                total: data.total,
            }
        });
    }

    getOne = (resource, params) => {
        return apiClient.professionalGet(params.id).then((data) => {
            return { data }
        });
    }

    getManyReference = (resource, params) => {
        const { page, perPage } = params.pagination;

        return apiClient.list({
            contractId: params.id,
            pageNumber: page,
            pageSize: perPage,
            ...params.filter
        }).then(
            res => {
                return {
                    data: res.elements,
                    total: res.total
                }
            }
        )
    }

    update = (resource, params) => {
        return apiClient.professionalPut(params.data).then((data) => ({
            data
        }));
    }

    create = (resource, params) => {
        return apiClient.professionalPost(params.data).then((data) => ({
            data
        }));
    }

    delete = (resource, params) => {
        return apiClient.professionalDelete(params.id).then(() => ({
            data: params.previousData
        }));
    }
};

export default new ProfessionalProvider();