import moment from 'moment';
import { ScheduledActivityClient } from '../services/ohl.virtualCenter.api';

const apiClient = new ScheduledActivityClient(process.env.REACT_APP_PUBLIC_API);

class ScheduledActivitiesOldProvider {

    getList = (resource, params) => {
        const { page, perPage } = params.pagination;
        let { field, order } = params.sort;

        if (field === 'Id') {
            field = 'StartDate';
            order = 'DESC';
        }

        if (params.filter && params.filter.startDateFrom) {
            params.filter.startDateFrom = moment(params.filter.startDateFrom).format('yyyy-MM-DDTHH:mm:ss')
        }
        if (params.filter.startDateTo) {
            params.filter.startDateTo = moment(params.filter.startDateTo).add(1, 'days').format('yyyy-MM-DDTHH:mm:ss')
        }

        if (params.filter.startDateFrom && params.filter.startDateTo){
            if (moment(params.filter.startDateTo).isBefore(params.filter.startDateFrom))
                return Promise.reject('La fecha hasta no puede ser anterior a la fecha desde')
        }

        return apiClient.list({
            pageNumber: page,
            pageSize: perPage,
            orderField: field,
            orderAsc: order === 'ASC',
            origin: "BackOffice",
            ...params.filter
        }).then((data) => {
            return {
                data: data.elements,
                total: data.total,
            }
        });
    }

    getOne = (resource, params) => {
        // params.origin = "BackOffice"
        return apiClient.scheduledActivityGet(params.id).then((data) => {
            return { data }
        });
    }

    getManyReference = (resource, params) => {
        const { page, perPage } = params.pagination;

        return apiClient.list({
            activityId: params.id,
            pageNumber: page,
            pageSize: perPage,
            origin: "BackOffice"
        }).then(
            res => {
                return {
                    data: res.elements,
                    total: res.total
                }
            }
        )
    }

    update = (resource, params) => {
        return apiClient.scheduledActivityPut(params.data).then((data) => ({
            data
        }));
    }

    create = (resource, params) => {
        return apiClient.scheduledActivityPost(params.data).then((data) => ({
            data
        }));
    }

    delete = (resource, params) => {
        return apiClient.scheduledActivityDelete(params.id).then(() => ({
            data: params.previousData
        }));
    }
};

export default new ScheduledActivitiesOldProvider();