import UserProvider from './user.provider';
import ActivitiesProvider from './activities.provider';
import ProfessionalProvider from './professional.provider';
import ContactsProvider from './contacts.provider';
import ContractsProvider from './contracts.provider';
import ContractingAuthorityProvider from './contractingAuthorities.provider';
import ScheduledActivitiesProvider from './scheduledActivities.provider';
import DevicesProvider from './devices.provider';
import CommunicationDeviceClient from './comunicationDevices.provider';
import UserHistoryProvider from './userHistory.provider';
import UserInteractionsProvider from './userInteractions.provider';
import UserDocumentsProvider from './userDocuments.provider';
import scheduledActivitiesOldProvider from './scheduledActivitiesOld.provider';
import installationsProvider from './installations.provider';
import housingPlanDocumentsProvider from './housingPlanDocuments.provider';
import townProvider from './town.provider';
class BaseProvider {

    providers = {
        users: UserProvider,
        activities: ActivitiesProvider,
        professional: ProfessionalProvider,
        admins: ProfessionalProvider,
        contacts: ContactsProvider,
        contracts: ContractsProvider,
        contractingAuthorities: ContractingAuthorityProvider,
        scheduledActivities: ScheduledActivitiesProvider,
        devices: DevicesProvider,
        installations: installationsProvider,
        comunicationDevices: CommunicationDeviceClient,
        userHistory: UserHistoryProvider,
        userInteractions: UserInteractionsProvider,
        userDocuments: UserDocumentsProvider,
        scheduledActivitiesOld: scheduledActivitiesOldProvider,
        superAdmins: ProfessionalProvider,
        housingPlanDocuments:housingPlanDocumentsProvider,
        towns: townProvider
    }

    _getProvider(resource) {
        if (!this.providers[resource]) {
            return Promise.reject("Provider " + resource + " not exist");
        }
        return this.providers[resource];
    }

    _capitalizeSort = (params) => {
        params.sort.field = params.sort.field.charAt(0).toUpperCase() + params.sort.field.slice(1);
        return params
    }

    getList = (resource, params) => {
        params = this._capitalizeSort(params);
        return this._getProvider(resource).getList(resource, params);
    }

    getOne = (resource, params) => {
        return this._getProvider(resource).getOne(resource, params);
    }

    getMany = (resource, params) => {
        const promiseArray = [];
        params.ids.forEach(e => {
            promiseArray.push(this._getProvider(resource).getOne('many', { id: e }))
        });
        return Promise.all(promiseArray).then(
            res => {
                const ret = res.map(e => e.data)
                return {
                    data: ret
                }
            }
        )
    }

    getManyReference = (resource, params) => {
        return this._getProvider(resource).getManyReference(resource, params);
    }

    update = (resource, params) => {
        return this._getProvider(resource).update(resource, params);
    }

    updateMany = (resource, params) => {
        return this._getProvider(resource).updateMany(resource, params);
    }

    create = (resource, params) => {
        return this._getProvider(resource).create(resource, params);
    }

    delete = (resource, params) => {
        return this._getProvider(resource).delete(resource, params);
    }

    deleteMany = (resource, params) => {
        const promiseArray = [];
        params.ids.forEach(e => {
            promiseArray.push(this._getProvider(resource).delete(resource, { id: e }))
        });
        return Promise.all(promiseArray).then(
            res => {
                const ret = res.map(e => e.data)
                return {
                    data: ret
                }
            }
        )
    }
};

export default new BaseProvider();