import { Box } from '@material-ui/core';
import Collaborators from './Collaborators';
import { useEffect, useState } from "react";
import contractsProvider from "../../provider/contracts.provider";
import authProvider from "../../provider/auth.provider";

export const Footer = props => {
    const [show, setShow] = useState(true);
    const [logos, setLogos] = useState(null);
    const [isVisible, setIsVisible] = useState(false);


    useEffect(() => {

        checkLocation()

        let locationInterval = setInterval(checkLocation, 1000)

        const fetchAuthAndData = async () => {
            const authResult = await authProvider.GetAuth();

            if (authResult?.contractLogoId && logos === null) {
            //if (authResult?.contractLogoId) {
                const logosResult = await contractsProvider.getLogos('', {
                    contractId: authResult.contractLogoId,
                });
                setLogos(logosResult);

                const timer = setTimeout(() => {
                    setIsVisible(true);
                }, 2000); // 3000ms = 3 segundos
    
                // Cleanup the timer if the component unmounts
                return () => clearTimeout(timer);
            }
        };

        fetchAuthAndData();

        return () => {
            clearInterval(locationInterval)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show])

    const checkLocation = () => {
        if (isLoginUrl(window.location.href) || isScheduledActivityUrl(window.location.href)) {
            setShow(false)  
        } else{
            setShow(true)  
        }
    }



    const isScheduledActivityUrl = (url) => {
        // Expresión regular para verificar si la URL contiene /scheduledActivities/{id}/show
        const scheduledActivityPattern = /\/#\/scheduledActivities\/\d+\/show/;
        return scheduledActivityPattern.test(url);
    }


    const isLoginUrl = (url) => {

        var responseLogin = url.includes('login');
        var responseChangePasswordPage = url.includes('changePasswordPage');
        var responseActivityCall = url.includes('activityCall');
        var responseAerialHabits = url.includes('AerialHabits');
        if(responseLogin || responseChangePasswordPage || responseActivityCall || responseAerialHabits){
            setLogos(null);
            setIsVisible(false);
        }

        var response = (responseLogin || responseChangePasswordPage);
        return response;
    }


    return (show && logos && (logos?.data?.panelDerecha !== null || logos?.data?.panelIzquierda !== null) ?
        <Box
        className={`footer ${isVisible ? 'visibleFooter' : 'hiddenFooter'}`}
        >
            <Collaborators logos={logos} />
        </Box> : null
    )
}
