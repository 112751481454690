import moment from "moment";
import { useGetIdentity, required, useNotify } from "ra-core";
import {
    Datagrid,
    List,
    TextField,
    DateInput,
    Show,
    SimpleShowLayout,
    FunctionField,
    ShowButton,
    Filter,
    TabbedForm,
    FormTab,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    AutocompleteArrayInput,
    ArrayInput,
    SimpleFormIterator,
    Button as RAButton,
    TabbedShowLayout,
    TabbedShowLayoutTabs,
    Tab
} from "ra-ui-materialui";
import { CustomToolbarPermissions } from "./utils";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { JitsiClient, ExternalInformationClient, InteractionDto, ScheduledActivityClient, SSEEvent, LoginClient } from "../services/ohl.virtualCenter.api";
// import { changeMainParticipant, changeViewSocket, connectSockets, lowQualityParticipants, refreshParticipants, toggleAudioParticipant, kickParticipant } from "../services/socket.service"
import { publish, notify as sseNotify } from "../services/sse.service";

// import FullCalendar from "@fullcalendar/react";
// import dayGridPlugin from '@fullcalendar/daygrid';
// import timeGridPlugin from '@fullcalendar/timegrid';
// import interactionPlugin from '@fullcalendar/interaction';
// import esLocale from '@fullcalendar/core/locales/es';
// import { useHistory } from "react-router-dom";

import Button from '@material-ui/core/Button';
import { iconListFont } from "./icons.js"

import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
import RefreshOutlined from '@material-ui/icons/RefreshOutlined';
import InputIcon from '@material-ui/icons/Input';
import CallEndIcon from '@material-ui/icons/CallEnd';
import { useFormState } from 'react-final-form';
import { getActivityType, getActivityTypeValue, getLanguajes, getLikes, getClientType, getWorkingCenter, getProfessionalType } from "../services/master-tables.service";

import InputLabel from '@material-ui/core/InputLabel';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TabsUseStyles, ChipArrayUseStyles } from "../App";
import { useRefresh, Labeled } from "react-admin";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';

import UserList from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
// import PersonIcon from '@material-ui/icons/Person';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import EjectIcon from '@material-ui/icons/Eject';
import PowerIcon from '@material-ui/icons/PowerSettingsNew';
import RefreshIcon from '@material-ui/icons/Refresh';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Tooltip from '@material-ui/core/Tooltip';
import { useHistory } from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../css/reactQuillMod.css';
import StarPicker from 'react-star-picker';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { JitsiMeeting } from "@jitsi/react-sdk";
// import { btoaReplacement } from "../common/utils";


const apiClient = new JitsiClient(process.env.REACT_APP_PUBLIC_API);
const loginClient = new LoginClient(process.env.REACT_APP_PUBLIC_API);
const interactionsClient = new ExternalInformationClient(process?.env?.REACT_APP_PUBLIC_API);
const scheduledActivityClient = new ScheduledActivityClient(process?.env?.REACT_APP_PUBLIC_API);

const ScheduledActivitiesFilter = props => (
    <Filter {...props}>
        <DateInput label="Fecha" source="startDateFrom" alwaysOn resettable={true} />
    </Filter>
)

const ScheduledActivitiesOldFilter = props => (
    <Filter {...props}>
        {/* <DateInput label="Fecha" source="startDateFrom" alwaysOn resettable={true} /> */}
        <DateInput label="Fecha Desde" source="startDateFrom" alwaysOn resettable={true} />
        <DateInput label="Fecha Hasta" source="startDateTo" alwaysOn resettable={true} />
    </Filter>
)

export const ScheduledActivitiesList = props => {
    const today = moment()?.startOf('day');
    return (
        <List {...props} exporter={false} filters={<ScheduledActivitiesFilter />} filterDefaultValues={{ startDateFrom: today?.format('yyyy-MM-DDTHH:mm:ss'), origin: "BackOffice" }}>
            <ScheduledActivitiesDataGrid></ScheduledActivitiesDataGrid>
        </List>
    )
}
export const ScheduledActivitiesOldList = props => {

    return (
        <List {...props} exporter={false} filters={<ScheduledActivitiesOldFilter />}>
            <ScheduledActivitiesDataGrid hideOptions={true}></ScheduledActivitiesDataGrid>
        </List>
        // <div className="FullCalendarApp">
        //     <FullCalendar
        //         headerToolbar={{
        //             start: 'prev,next today',
        //             center: 'title',
        //             end: 'dayGridMonth,timeGridWeek,timeGridDay',
        //         }}
        //         initialDate="2022-08-01"
        //         locale={esLocale}
        //         plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        //         selectable={true}
        //         navLinks={true}
        //         eventClick={handleEventClick}
        //         datesSet={dateInfoCallback}
        //         events={scheduledList}
        //     />
        // </div>
    );
}


const ScheduledActivitiesOldTitle = () => {
    return <span>Histórico</span>;
};

export const ScheduledActivitiesOldShow = props => (
    <Show title={<ScheduledActivitiesOldTitle />} {...props}>
        {ScheduledActivitiesOldShowForm(props)}
    </Show>
);

export const parseDate = (date) => {
    // return moment(date).calendar(null, {
    //     sameElse: 'DD/MM/YYYY HH:mm'
    // });
    return moment(date).format('DD/MM/YYYY HH:mm');
}

const isToday = (date) => {
    const TodayObj = new Date();

    return date.getDate() === TodayObj?.getDate() &&
        date.getMonth() === TodayObj?.getMonth() &&
        date.getFullYear() === TodayObj?.getFullYear()
}

const RenderOptions = (record, identity, loading) => {
    if (loading && !identity) {
        return null;
    }

    const SendDoPolling = () => {
        record.participants.forEach(p => {
            sseNotify(new SSEEvent({clientId: p.id+"", jitsiRoom: "Landing", type: 'DoPolling'}))
        })
    }

    if (isToday(record.startDate)) {
        if (!record?.isHeld) {
            if (identity.roles === "DYNAMICS_MANAGER" || identity?.roles === "ALL_MANAGER" || identity?.roles === "ADMIN") {
                return (
                    <>
                        <ShowButton
                            basePath="/scheduledActivities"
                            label="Comenzar"
                            icon={<PlayCircleFilledWhiteIcon />}
                            record={record}
                        />
                        <ShowButton
                            label="Actualizar Agenda"
                            onClick={SendDoPolling}
                            icon={<RefreshOutlined />}
                        />
                    </>
                )
            } else {
                return "No ha comenzado todavía"
            }
        } else {
            if (record?.realEndDate) {
                return "Actividad finalizada"
            } else {
                return (
                    <div>
                        {/* <StartActivityButton record={record}/> */}
                        <ShowButton
                            basePath="/scheduledActivities"
                            label="Entrar"
                            icon={<InputIcon />}
                            record={record}
                        />
                    </div>
                )
            }
        }
    } else {
        return null
    }
}

const transformScheduledActivityEdit = (data) => {
    const startDate = data?.startDate ? moment(data?.startDate).format('yyyy-MM-DDTHH:mm:ss') : null;
    const endDate = data?.endDate ? moment(data?.endDate).format('yyyy-MM-DDTHH:mm:ss') : null;
    const activity = { id: data?.activity?.id };
    const realStartDate = data?.realStartDate ? moment(data?.realStartDate).format('yyyy-MM-DDTHH:mm:ss') : null;
    const realEndDate = data?.realEndDate ? moment(data?.realEndDate).format('yyyy-MM-DDTHH:mm:ss') : null;
    return {
        ...data,
        startDate,
        endDate,
        activity: activity,
        interactions: null,
        realStartDate: realStartDate,
        realEndDate: realEndDate
    }
}

export const ScheduledActivitiesDataGrid = props => {
    const { identity, loading } = useGetIdentity();
    const [activityType, setactivityType] = useState();
    const [activityTypeValue, setactivityTypeValue] = useState();

    useEffect(() => {
        getActivityType().then(
            res => {
                setactivityType(res?.elements);
            }
        )
        getActivityTypeValue().then(
            res => {
                setactivityTypeValue(res?.elements);
            }
        )
    }, []);


    return (
        <Datagrid className="full" rowClick={props.rowClick}>
            <TextField label="Nombre actividad" source="activity.name" sortable={true} sortBy="Activity.Name" />
            <FunctionField label="Responsable" style={{ whiteSpace: 'nowrap' }} render={record => {
                return record.professional.name + ' ' + record.professional.surnames
            }} />
            {/* <FunctionField label="Tipo de Sesión" style={{ whiteSpace: 'nowrap' }} render={record => {
                return record.activity?.primaryTypeDescription ? record.activity?.primaryTypeDescription + ' ' + (record.activity?.secondaryTypeDescription ? record.activity?.secondaryTypeDescription : '') : ''
            }} /> */}
            <FunctionField label="Tipo de Sesión" style={{ whiteSpace: 'nowrap' }} render={record => {
                return record?.activity?.primaryTypeId ? activityType?.filter(x => x.id === record?.activity?.primaryTypeId)[0]?.value + ' ' 
                + (record?.activity?.secondaryTypeId ? activityTypeValue?.filter(x => x.id === record?.activity?.secondaryTypeId)[0]?.value : '') : ''
            }} />
            <FunctionField label="Fecha" style={{ whiteSpace: 'nowrap' }} className="capitalize" sortable={true} sortBy="startDate" render={record => moment(record?.startDate).format('DD/MM/YYYY')}/>
            <FunctionField label="Hora inicio" style={{ whiteSpace: 'nowrap' }} className="capitalize" render={record => moment(record?.startDate).format('HH:mm')} />
            <FunctionField label="Hora Fin" style={{ whiteSpace: 'nowrap' }} className="capitalize" render={record => moment(record?.endDate).format('HH:mm')} />
            <FunctionField label="Estado" className="state" sortable={true} sortBy="State.Name" render={record => {
                return record?.state === record?.isHeld ? "En curso" : record?.isCancel ? "Cancelada" : !!record?.realEndDate ? "Finalizada" : "Pendiente"
            }} />
            <span></span>
            {props?.hideOptions ? null :
                <FunctionField label="Opciones" render={record => RenderOptions(record, identity, loading)} />
            }
        </Datagrid>
    )
}

// const VideoIframe = (props) => {
//     if(!props.jitsiRoom || !props.token) return null
//     const url = process.env.REACT_APP_JITSI_PUBLIC_API + props.jitsiRoom + '?userInfo.displayName=DiegoMobile_R123456&jwt=' + props.token
//     console.log('Loading iframe ' + url)
//     return (
//         <iframe 
//             title="Jitsi" 
//             allow="microphone; camera" 
//             src={url} 
//         />
//     )
// }

const callParticipants = (record) => {
    return apiClient?.initRoom(record?.id).then(
        res => {
            loginClient.renew().then(res => {
                localStorage.setItem('token', res.accessToken)
            })
            console.log('Room init! ' + res?.room);
            return res
        }
    )
}

const endActivity = (record, refresh) => {
    return apiClient?.end(record?.id).then(
        res => {
            loginClient.renew().then(res => {
                localStorage.setItem('token', res.accessToken)
            })
            console.log('Activity ended!');
            refresh();
            return res;
        }
    )
}

export const ScheduledActivitiesShow = props => {
    const [userList, setuserList] = useState([])
    const [myJitsiId, setmyJitsiId] = useState()
    const [api, setapi] = useState()
    const [token, setToken] = useState()
    const [roomName, setRoomName] = useState()
    const [showJitsiMeeting, setShowJitsiMeeting] = useState(true)
    const docSelector = useRef();

    useLayoutEffect(() => {
        if (api) return
        callParticipants(props).then(
            res => {
                setToken(res.token)
                setRoomName(res.room)
                console.log('Loading iframe', docSelector?.current)
                if (!docSelector?.current) return
            }
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!token || !roomName) return null
    return (
        <Show {...props} aside={<ScheduledActivitiesAside onEndCall={() => setShowJitsiMeeting(false)} userList={userList} myJitsiId={myJitsiId} api={api} />}>
            <SimpleShowLayout>
            {
                showJitsiMeeting ? 
                    <JitsiMeeting
                        domain = { process.env.REACT_APP_JITSI_PUBLIC_API }
                        roomName = {roomName}
                        configOverwrite = {{
                            prejoinPageEnabled: false, disableDeepLinking: true, lang: 'es',
                            disableSelfView: false,
                            disableLocalVideoFlip: true,
                            // disableRemoteMute: false,
                            disableSelfViewSettings: true,
                            remoteVideoMenu:
                            {
                                disableKick: true,
                                // disabled: true,
                                disableGrantModerator: true
                            }
                        }}
                        interfaceConfigOverwrite = {{
                            DISABLE_JOIN_LEAVE_NOTIFICATIONS: true
                        }}
                        userInfo = {{
                            displayName: 'Organizador'
                        }}
                        onApiReady = { (api) => {
    
                            const updateParticipants = () => {
                                const participantInfo = api.getParticipantsInfo();
                                console.log('Participans: ', participantInfo);
                                setuserList(participantInfo)
                            }
            
                            const updateMyJitsiId = (data) => {
                                console.log('MyId: ', data.id);
                                setmyJitsiId(data.id)
                            }
            
                            api.addEventListeners({
                                readyToClose: () => console.log('Event: readyToClose'),
                                participantLeft: () => updateParticipants(),
                                participantJoined: () => updateParticipants(),
                                videoConferenceJoined: (data) => {
                                    updateMyJitsiId(data)
                                    updateParticipants()
                                    setapi(api);
                                    setTimeout(() => {
                                        api.executeCommand('setVideoQuality', 720)
                                    }, 2000)
                                    api.executeCommand('grantModerator',data?.id);
                                },
                                videoConferenceLeft: () => console.log('Event: videoConferenceLeft'),
                                audioMuteStatusChanged: () => console.log('Event: audioMuteStatusChanged'),
                                videoMuteStatusChanged: () => console.log('Event: videoMuteStatusChanged')
                            });
                        } }
                        getIFrameRef = { (iframeRef) => { iframeRef.style.height = '100%'; } }
                        jwt={token}
                    />
                : null
            }
            </SimpleShowLayout>
        </Show>
    )
};

export const ScheduledActivitiesOldShowForm = (props, mode = 'show') => {
    const [languajeList, setlanguajeList] = useState()
    const [workingCenter, setworkingCenter] = useState();
    const [activityType, setactivityType] = useState();
    const [likeList, setlikeList] = useState()
    // const [clientTypes, setclientTypes] = useState()
    const [iconList, seticonList] = useState()
    const classes = TabsUseStyles();
    const chipClasses = ChipArrayUseStyles();
    const { identity, loading } = useGetIdentity();
    const [professionalTypes, setprofessionalTypes] = useState()



    useEffect(() => {
        getLanguajes().then(
            res => {
                setlanguajeList(res.elements);
            }
        )
        getLikes().then(
            res => {
                setlikeList(res.elements);
            }
        )
        getClientType().then(
            res => {
                // setclientTypes(res.elements);
            }
        )
        getWorkingCenter().then(
            res => {
                setworkingCenter(res.elements);
            }
        )
        getActivityType().then(
            res => {
                setactivityType(res.elements);
            }
        )
        getProfessionalType().then(
            res => {
                setprofessionalTypes(res.elements);
            }
        )
        seticonList(iconListFont)

    }, []);

    if (loading && !identity) {
        return <div></div>;
    }


    return (
        <TabbedForm {...props} toolbar={<CustomToolbarPermissions perm="activities.delete" />} classes={classes}>
            <FormTab label="Actividad" contentClassName="tab-container" tabIndex={1000} index={1000}>
                <TextInput label="Nombre Actividad" source="activity.name" disabled={identity?.roles === "CONTRACTING_AUTHORITY"} />
                <TextInput label="Descripción" source="activity.description" disabled={identity?.roles === "CONTRACTING_AUTHORITY"} />
                <ReferenceInput
                    label="Contrato"
                    source="activity.relatedContract.id"
                    reference="contracts"
                    filterToQuery={(value) => {
                        return { nameINumber: value }
                    }}
                    disabled={identity.roles === "CONTRACTING_AUTHORITY"}
                >
                    <AutocompleteInput optionText={(record) => record?.contractNumber} resettable={true} />
                </ReferenceInput>
                <ContractProfessionalsInput professionalTypes={professionalTypes} source="professional.id" disabled={identity?.roles === "CONTRACTING_AUTHORITY"}></ContractProfessionalsInput>
                {/* <TextInput label="Icono" source="icon" /> */}
                {
                    iconList ?
                        <AutocompleteInput
                            label="Icono"
                            source="activity.icon"
                            choices={iconList}
                            // optionText="value"
                            optionText={<OptionIconRenderer />}
                            inputText={choice => `${choice?.value}`}
                            matchSuggestion={(filterValue, suggestion) => {
                                return suggestion ? suggestion?.value?.includes(filterValue) : false
                            }}
                            optionValue="value"
                            // resettable={true}
                            suggestionLimit={25}
                            options={{ InputProps: { autoComplete: 'off' } }}
                            resettable={true}
                            disabled={identity?.roles === "CONTRACTING_AUTHORITY"}
                        />
                        : null
                }
                {
                    likeList ?
                        <AutocompleteArrayInput
                            label="Gustos/Preferencias"
                            source="activity.clientsLikesId"
                            choices={likeList}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' } }}
                            className="full"
                            classes={chipClasses}
                            disabled={identity?.roles === "CONTRACTING_AUTHORITY"}
                        />
                        : null
                }
                {/* {
                    clientTypes ?
                        <AutocompleteInput
                            label="Perfil de usuario"
                            source="clientTypeId"
                            choices={clientTypes}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' } }}
                            classes={chipClasses}
                            resettable={true}
                        />
                        : null
                } */}
                {
                    languajeList ?
                        <AutocompleteInput
                            label="Idioma"
                            source="activity.languageId"
                            choices={languajeList}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' } }}
                            resettable={true}
                            disabled={identity.roles === "CONTRACTING_AUTHORITY"}
                        />
                        : null
                }
                {
                    workingCenter ?
                        <AutocompleteInput
                            label="Centro de Trabajo"
                            source="activity.associatedWorkingCenterId"
                            choices={workingCenter}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' }, autoComplete: 'off' }}
                            resettable={true}
                            disabled={identity?.roles === "CONTRACTING_AUTHORITY"}
                        />
                        : null
                }
                {
                    activityType ?
                        <AutocompleteInput
                            label="Tipología dinámica"
                            source="activity.primaryTypeId"
                            choices={activityType}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' }, autoComplete: 'off' }}
                            resettable={true}
                            disabled={identity?.roles === "CONTRACTING_AUTHORITY"}
                        />
                        : null
                } 
                <ActivityTypeValueInput source="activity.secondaryTypeId"></ActivityTypeValueInput>
            </FormTab>
            <FormTab label="Participantes">
                <ContractParticipantsInput source="id"></ContractParticipantsInput>
                <TextInput style={{ display: 'none' }} source="participantsValidation" />
            </FormTab>
            {
                // mode === 'edit' ?
                //     <FormTab label="Agenda" contentClassName="">
                //         <ReferenceManyField
                //             className="full"
                //             pagination={<Pagination />}
                //             addLabel={false}
                //             target="id"
                //             reference="scheduledActivities"
                //             perPage={10}
                //         >
                //             <Datagrid className="full">
                //                 <TextField label="Nombre actividad" source="activity.name" />
                //                 {/* <DateField label="Fecha inicio" source="startDate" /> */}
                //                 <FunctionField label="Fecha inicio" className="capitalize" render={record => parseDate(record.startDate)} />
                //                 <BooleanField label="Cancelada" source="isCancel" />
                //                 <BooleanField label="Comenzada" source="isHeld" />
                //                 <FunctionField label="Finalizada" render={record => {
                //                     return !!record.realEndDate ? <Done color="primary" /> : <Clear />
                //                 }} />
                //                 {/* <FunctionField label="" render={record => {
                //                     return !record.isCancel && !record.isHeld && !record.realEndDate && props.permissions && props.permissions.activities.edit ? <EditScheduledActivityButton record={record} /> : null
                //                 }} />
                //                 <FunctionField label="" render={record => {
                //                     return !record.isCancel && !record.isHeld && !record.realEndDate && props.permissions && props.permissions.activities.delete ? <DeleteScheduledActivityButton record={record} /> : null
                //                 }} /> */}
                //             </Datagrid>
                //         </ReferenceManyField >
                //     </FormTab>
                //     :
                //     <FormTab label="Periodicidad" contentClassName="tab-container">
                //         <DateInput label="Fecha de inicio" source="initDate"></DateInput>
                //         <div style={{ width: '98%' }}>
                //             <TextInput style={{ marginRight: 5 }} label="Hora inicio (HH:MM)" source="initHour" ></TextInput>
                //             <TextInput style={{ marginLeft: 5 }} label="Hora fin (HH:MM)" source="endHour" ></TextInput>
                //         </div>
                //         <SelectInput label="Periodicidad" source="periodicy" choices={[
                //             { id: 'Once', name: 'Una vez' },
                //             { id: 'EveryWeek', name: 'Cada semana' },
                //             { id: 'EveryMonth', name: 'Cada Mes' },
                //             { id: 'EveryYear', name: 'Cada año' },
                //         ]} />
                //         <FormDataConsumer>
                //             {({ formData, ...rest }) => formData.periodicy !== 'Once' &&
                //                 <NumberInput label="Cantidad de eventos" source="amount"></NumberInput>
                //             }
                //         </FormDataConsumer>
                //     </FormTab>
            }
        </TabbedForm>
    )
}

const ContractParticipantsInput = props => {
    const { values } = useFormState();
    const { identity, loading } = useGetIdentity();

    if (loading && !identity) {
        return <div></div>;
    }

    // if (!values.relatedContract?.id) return null

    return (
        <ArrayInput label="Participantes" source="participants" validate={[required()]} disabled={identity?.roles === "CONTRACTING_AUTHORITY"}>
            <SimpleFormIterator>
                <ReferenceInput label="Usuario" source="id" reference="users"
                    filter={{ contractId: values?.relatedContract?.id }}
                    filterToQuery={(value) => {
                        return { nameINumber: value }
                    }}
                >
                    <AutocompleteInput
                        optionText={(record) => record?.identificationNumber + ' - ' + record?.name + ' ' + record?.surnames}
                        validate={[required()]}
                    />
                </ReferenceInput>
            </SimpleFormIterator>
        </ArrayInput>
    );
};

const ActivityTypeValueInput = props => {
    const { values } = useFormState();
    const [activityTypeValue, setactivityTypeValue] = useState();

    useEffect(() => {
        if(values?.activity?.primaryTypeId) {
            getActivityType().then(
                res => {
                    var filtered = res?.elements?.filter(e => e?.id === values?.activity?.primaryTypeId);
                    getActivityTypeValue(filtered && filtered?.length > 0? filtered[0]?.code: null).then(
                        res => {
                            setactivityTypeValue(res?.elements);
                            values.activity.secondaryTypeId = null;
                        }
                    )
                }
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values?.activity?.primaryTypeId])

    if (!values?.activity?.primaryTypeId) return null

    return (
        <AutocompleteInput
            label="Subtipología dinámica"
            source="activity.secondaryTypeId"
            choices={activityTypeValue}
            optionText="value"
            optionValue="id"
            options={{ InputProps: { autoComplete: 'off' }, autoComplete: 'off' }}
            resettable={true}
        />
    );
};

const ContractProfessionalsInput = props => {
    const { professionalTypes } = props;
    const { values } = useFormState();
    const { identity, loading } = useGetIdentity();
    var filterType = ['SOCIAL_WORKER', 'PSYCHOLOGIST', 'TASOC', 'THERAPIST'];

    if (!values?.activity?.relatedContract?.id) return null

    if (loading && !identity) {
        return <div></div>;
    }

    var filterId = professionalTypes?.filter(item => filterType.includes(item.value)).map(item => item.id);

    return (
        <ReferenceInput
            label="Responsable"
            source="professional.id"
            reference="professional"
            filter={{ type: ['SOCIAL_WORKER', 'PSYCHOLOGIST', 'TASOC', 'THERAPIST'], contractId: values?.activity?.relatedContract.id }}
            filterToQuery={(value) => {
                return { nameINumber: value, professionalTypeId:filterId }
            }}
            disabled={identity.roles === "CONTRACTING_AUTHORITY"}
        >
            <AutocompleteInput optionText={(record) => record?.identificationNumber + ' - ' + record?.name + ' ' + record?.surnames} resettable={true} />
        </ReferenceInput>
    );
};


// const ScheduledActivitiesOldShowButtons = (props) => {
//     const record = useRecordContext(props);
//     return (
//         <div style={{ display: 'flex' }}>
//             <EditButton basePath="/users" label="Ver usuario" record={record} />
//             <SimpleForm toolbar={null}>
//                 <AddNewUserInteractionsButton {...props} record={record} ></AddNewUserInteractionsButton>
//             </SimpleForm>
//         </div>
//     )
// }

export const ScheduledActivitiesAside = (props) => {
    const [showDialog, setshowDialog] = useState(false);
    const { record, userList, myJitsiId, api } = props;
    const [interactionList, setInteractionList] = useState(record?.interactions);
    const [generalComment, setGeneralComment] = useState();
    const [layout, setlayout] = useState('split')
    const [anchorEl, setAnchorEl] = useState(null);
    const [mainParticipant, setmainParticipant] = useState(myJitsiId);
    const [hasBeenWarned, setHasBeenWarned] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();
    let history = useHistory();
    const [databaseOperationInProgress, setDataBaseOperationInProgress] = useState(false);
    const [endActivityPressed, setEndActivityPressed] = useState(false);
    const [endActivityProcessing, setEndActivityProcessing] = useState(false);
    const [disableButtons, setDisableButtons] = useState(false);
    const [disabledResetButton, setDisabledResetButton] = useState({});
    const [disabledKickUser, setDisabledKickUser] = useState({});
    const [disabledRefreshUser, setDisabledRefreshUser] = useState({});

    // useEffect(() => {
    //     var interactionListClone = [...interactionList]
    //     // interactionListClone.forEach(element => {
    //     //     if (element.comment) {
    //     //         element.comment = atob(element.comment);
    //     //         element.technicalComment = atob(element.technicalComment);
    //     //     }
    //     // });
    //     setInteractionList(interactionListClone);
    // }, []);
    if (!record) {
        return null;
    }

    const onChangeGeneralComment = (value) => {
        setGeneralComment(value);
    };

    const onChangeComment = (value, index) => {
        var interactionListClone = [...interactionList]
        interactionListClone[index].comment = value;
        record.interactions[index].comment = value;
        setInteractionList(interactionListClone);
    };

    const onChangeTechComm = (value, index) => {
        var interactionListClone = [...interactionList]
        interactionListClone[index].technicalComment = value;
        record.interactions[index].technicalComment = value;
        setInteractionList(interactionListClone);
    };

    const onChangeRelativesComm = (value, index) => {
        var interactionListClone = [...interactionList]
        interactionListClone[index].commentForRelative = value;
        record.interactions[index].commentForRelative = value;
        setInteractionList(interactionListClone);
    };

    const onStarClickMood = (nextValue, index) => {
        var interactionListClone = [...interactionList]
        interactionListClone[index].mood = nextValue;
        record.interactions[index].mood = nextValue;
        setInteractionList(interactionListClone);
    };

    const onStarClickHealth = (nextValue, index) => {
        var interactionListClone = [...interactionList]
        interactionListClone[index].healthCondition = nextValue;
        record.interactions[index].healthCondition = nextValue;
        setInteractionList(interactionListClone);
    };
    const onStarClickFeedback = (nextValue, index) => {
        var interactionListClone = [...interactionList]
        interactionListClone[index].feedbackMark = nextValue;
        record.interactions[index].feedbackMark = nextValue;
        setInteractionList(interactionListClone);
    };

    const handleClick = () => {
        setEndActivityPressed(true);
        setEndActivityProcessing(true);
        endActivity(record, refresh).then(
            activity => {
                props.onEndCall()
                record.interactions = activity?.interactions;
                setInteractionList(activity?.interactions);
                setshowDialog(true);
                setEndActivityProcessing(false);
            }
        ).catch(err => {
            setEndActivityPressed(false);
            setEndActivityProcessing(false);
            if (err?.error?.code === 4001) {
                if (err?.error?.message?.includes('ha sido finalizada')) {
                    history?.push('/scheduledActivities')
                } else {
                    notify(err?.error?.message, 'warning');
                }
            }
            else
                notify("No se ha podido finalizar la llamada. Inténtelo de nuevo en unos segundos", 'warning');
        })
    };
    const handleCloseClick = async () => {
        var allFilledIn = true;
        interactionList.forEach(element => {
            if (!element?.comment || !element?.technicalComment || !element?.commentForRelative || !element?.mood || !element?.healthCondition || !element?.feedbackMark) {
                allFilledIn = false;
            }
        });
        if (allFilledIn || hasBeenWarned) {

            var updatedInteractionList = [];
            setDisableButtons(true)
            interactionList.forEach(element => {
                // element.comment = element?.comment ? btoaReplacement(element?.comment) : null;
                // element.technicalComment = element?.technicalComment ? btoaReplacement(element.technicalComment) : null;
                // element.commentForRelative = element?.commentForRelative ? btoaReplacement(element?.commentForRelative) : null;
                if (!element?.mood) {
                    element.mood = 0;
                }
                if (!element?.feedbackMark) {
                    element.feedbackMark = 0;
                }
                if (!element?.healthCondition) {
                    element.healthCondition = 0;
                }
                var updatedInteraction = new InteractionDto();
                Object.keys(element).forEach(e => {
                    if (e === "creationDate") {
                        updatedInteraction[e] = null;
                    } else {
                        updatedInteraction[e] = element[e];
                    }
                });
                updatedInteractionList.push(updatedInteraction);
            });
            if (generalComment) {
                record.comments = generalComment;
            }
            setDataBaseOperationInProgress(true);
            await scheduledActivityClient.scheduledActivityPut(transformScheduledActivityEdit(record));
            await interactionsClient?.many(updatedInteractionList);
            setDataBaseOperationInProgress(false);
            setDisableButtons(false)
            history?.push("/scheduledActivities");
        } else {
            setHasBeenWarned(true);
            notify('No has rellenado todos los campos de las interacciones', 'warning');
        }
    };
    const handleCancelClick = () => {
        history?.push("/scheduledActivities");
    };
    // const toolbarOptions = [['bold', 'italic', 'underline', 'link'], [{ 'color': [] }, { 'background': [] }],]

    const handleClickUser = (user) => {
        const win = window?.open('/#/users/' + user.id, "_blank");
        win?.focus();
    };

    // const copyUrl = () => {
    //     const token = localStorage.getItem('token');
    //     var copyText = window.location.origin + '/#/activityCall/' + record.id + '/' + token;

    //     /* Copy the text inside the text field */
    //     navigator.clipboard.writeText(copyText);

    // }

    const handleMenuClick = (event) => {
        setAnchorEl(event?.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    // const handleUnmuteUser = (participant) => {
    //     toggleAudioParticipant(record.id, participant ? participant.id : 'all');
    //     setAnchorEl(null);
    // }
    // const handleRefreshUser = (participant) => {
    //     apiClient.refreshAllClients(record.id)
    //     refreshParticipants(record.id, participant ? participant.id : 'all');
    //     setAnchorEl(null);
    // }
    // const handleLowQualityUser = (participant) => {
    //     lowQualityParticipants(record.id, participant ? participant.id : 'all');
    //     api.executeCommand('setVideoQuality', 320);
    //     setAnchorEl(null);
    // }
    // const handleKickUser = (user) => {
    //     kickParticipant(record.id, user ? user.id : null);
    //     setAnchorEl(null);
    // }

    // const handleChangeLayout = (event) => {
    //     setlayout(event.target.value);
    //     setmainParticipant(myJitsiId);
    //     changeViewSocket(record.id, event.target.value)
    //     if (event.target.value === 'slide') {
    //         changeMainParticipant(record.id, myJitsiId)
    //     }
    // };
    // const handleChangeMainParticipant = (event) => {
    //     setmainParticipant(event.target.value);
    //     changeMainParticipant(record.id, event.target.value)
    // };
    // const handleUpdate = () => {
    //     changeViewSocket(record.id, layout)
    //     if (layout === 'slide') {
    //         changeMainParticipant(record.id, mainParticipant)
    //     }
    // };

    // const handleChangeLayout = (event) => {
    //     setlayout(event.target.value);
    //     setmainParticipant(myJitsiId);
    //     publish(new SSEEvent({clientId: 'all', jitsiRoom: record.id.toString(), type: 'ChangeLayout', data: event.target.value}));
    //     if (event.target.value === 'slide') {
    //         handleChangeMainParticipant(event, myJitsiId)
    //     }
    // };
    // const handleChangeMainParticipant = (event, mainParticipant = null) => {
    //     setmainParticipant(event.target.value);
    //     publish(new SSEEvent({clientId: 'all', jitsiRoom: record.id.toString(), type: 'ChangeMainParticipant', data: mainParticipant? mainParticipant: myJitsiId}));
    // };
    const handleUpdate = () => {
        if (layout === 'split')
            publish(new SSEEvent({clientId: 'all', jitsiRoom: record.id.toString(), type: 'ChangeLayout', data: layout}));
        if (layout === 'slide') {
            publish(new SSEEvent({clientId: 'all', jitsiRoom: record.id.toString(), type: 'ChangeMainParticipant', data: mainParticipant}));
        }
    };

    const handleCallParticipants = (participant) => {
        //callParticipants(record);
        record.participants.forEach(p => {
            sseNotify(new SSEEvent({clientId: p?.id+"", jitsiRoom: "Landing", type: 'Ring', data: record?.id?.toString()}))
        })
        setAnchorEl(null);
    }
    const handleUnmuteUser = (participant) => {
        participant?
            sseNotify(new SSEEvent({clientId: participant?.id + "webview", jitsiRoom: record?.id?.toString(), type: 'ToggleAudioParticipant'}))
        : publish(new SSEEvent({clientId: "all", jitsiRoom: record?.id?.toString(), type: 'ToggleAudioParticipant'}));
        setAnchorEl(null);
    }
    const handleRefreshUser = (participant) => {
        // Desactivar el botón correspondiente
        setDisabledRefreshUser(prevState => ({
            ...prevState,
            [participant?.id]: true  // Desactiva el botón específico por el id del participante
        }));
        participant?
            sseNotify(new SSEEvent({clientId: participant?.id + "webview", jitsiRoom: record?.id?.toString(), type: 'RefreshParticipant'}))
        : publish(new SSEEvent({clientId: "all", jitsiRoom: record?.id?.toString(), type: 'RefreshParticipant'}));
        setAnchorEl(null);

        // Reactivar el botón después de 3 segundos
        setTimeout(() => {
            setDisabledRefreshUser(prevState => ({
                ...prevState,
                [participant?.id]: false
            }));
        }, 3000);
    }
    const handleLowQualityUser = (participant) => {
        participant?
            sseNotify(new SSEEvent({clientId: participant?.id + "webview", jitsiRoom: record?.id?.toString(), type: 'LowQualityParticipants'}))
        : publish(new SSEEvent({clientId: "all", jitsiRoom: record?.id?.toString(), type: 'LowQualityParticipants'}));
        api.executeCommand('setVideoQuality', 320);
        setAnchorEl(null);
    }
    const handleKickUser = (user) => {
        // Desactivar el botón correspondiente
        setDisabledKickUser(prevState => ({
            ...prevState,
            [user?.id]: true  // Desactiva el botón específico por el id del participante
        }));

        sseNotify(new SSEEvent({clientId: user?.id + "webview", jitsiRoom: record?.id?.toString(), type: 'KickParticipant'}));
        sseNotify(new SSEEvent({clientId: user?.id + "room", jitsiRoom: record?.id?.toString(), type: 'KickParticipant'}));
        setAnchorEl(null);

        // Reactivar el botón después de 3 segundos
        setTimeout(() => {
            setDisabledKickUser(prevState => ({
                ...prevState,
                [user?.id]: false
            }));
        }, 3000);
    }
    const handleResetApp = (user) => {
        // Desactivar el botón correspondiente
        setDisabledResetButton(prevState => ({
            ...prevState,
            [user?.id]: true  // Desactiva el botón específico por el id del participante
        }));

        sseNotify(new SSEEvent({clientId: user?.id + "webview", jitsiRoom: record?.id?.toString(), type: 'CSVExitApp'}));
        sseNotify(new SSEEvent({clientId: user?.id + "room", jitsiRoom: record?.id?.toString(), type: 'CSVExitApp'}));
        sseNotify(new SSEEvent({clientId: user?.id+"", jitsiRoom: "Landing", type: 'CSVExitApp'}))
        //setTimeout(() => setDisabledResetButton(false), 3000); 

        // Reactivar el botón después de 3 segundos
        setTimeout(() => {
            setDisabledResetButton(prevState => ({
                ...prevState,
                [user?.id]: false
            }));
        }, 3000);

        // Aquí estamos agregando el retraso de 7 segundos para la notificación de tipo 'Ring'
        setTimeout(() => {
            sseNotify(new SSEEvent({clientId: user?.id+"", jitsiRoom: "Landing", type: 'Ring', data: record?.id?.toString()}));
        }, 7000);    
        setAnchorEl(null);
    }


    return (
        <div style={{ width: 200, margin: '1em', display: 'flex', flexDirection: 'column' }}>
            <h3>{record?.activity?.name}</h3>
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                <Button style={{ marginBottom: 50 }} variant="contained" color="primary" onClick={handleMenuClick}>
                    Menú de acciones
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={() => handleCallParticipants()}>Llamar a todos</MenuItem>
                    <MenuItem onClick={() => handleUnmuteUser()}>Activar sonido a todos</MenuItem>
                    <MenuItem onClick={() => handleRefreshUser()}>Recargar videollamada a todos</MenuItem>
                    <MenuItem onClick={() => handleLowQualityUser()}>Bajar calidad a todos</MenuItem>
                    {/* <MenuItem onClick={() => copyUrl()}>Copiar URL</MenuItem> */}
                </Menu>
                {
                    record?.participants && record?.participants?.length ?
                        <span style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                            <span style={{ fontWeight: 600 }}>Participantes: </span>
                            {userList.length}
                        </span>
                        : null

                }
                <Accordion style={{ marginTop: 10 }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Layout</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ display: 'flex', flexDirection: 'column' }}>
                        <FormControl>
                            <InputLabel id="demo-simple-select-label">Layout</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={layout}
                                onChange={e => setlayout(e?.target?.value)}>
                                <MenuItem value={'split'}>Cuadrícula</MenuItem>
                                <MenuItem value={'slide'}>Foco</MenuItem>
                            </Select>
                        </FormControl>
                        {
                            userList && layout === 'slide' ?
                                <FormControl>
                                    <InputLabel id="demo-simple-select-label">Participante principal</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={mainParticipant}
                                        onChange={(e) => setmainParticipant(e?.target?.value)}>
                                        {/* <MenuItem value={'profesional'}>Yo</MenuItem> */}
                                        <MenuItem value="auto">Automático</MenuItem>
                                        {
                                            userList?.map(participant =>
                                                <MenuItem value={participant?.participantId}>{participant?.displayName ? participant?.displayName : participant?.participantId}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                                : null
                        }
                        <Button color="primary" variant="contained" style={{ marginTop: 10 }} onClick={() => handleUpdate()}>Actualizar</Button>
                    </AccordionDetails>
                </Accordion>
                <Accordion style={{ marginTop: 10 }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography>Participantes</Typography>
                    </AccordionSummary>
                    <AccordionDetails className="participant-accordion" style={{ display: 'flex', flexDirection: 'column' }}>
                        <UserList dense={true} component="nav" aria-label="main mailbox folders">
                            {
                                record.participants.map(participant =>
                                    <ListItem style={{display: 'flex', alignItems: 'center'}}>
                                        <Tooltip title={(getParticipantName(participant)) + " (Ver Ficha)"}>
                                            <ListItemText onClick={() => handleClickUser(participant)} style={{ fontSize: 12, cursor: 'pointer' }} primary={getParticipantName(participant)}
                                                primaryTypographyProps={{
                                                    variant: 'subtitle2',
                                                    style: {
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        width: '38%'
                                                    }
                                                }} />
                                        </Tooltip>
                                        <ListItemSecondaryAction>
                                            {/* <Tooltip title='Ver Ficha de Usuario'>
                                                <IconButton onClick={() => handleClickUser(participant)} edge="end">
                                                    <PersonIcon style={{fontSize: 16}} />
                                                </IconButton>
                                            </Tooltip> */}
                                            <Tooltip title='Reactivar Audio al Usuario'>
                                                <IconButton onClick={() => handleUnmuteUser(participant)} edge="end">
                                                    <VolumeUpIcon style={{fontSize: 16}} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title='Recargar Videollamada al Usuario'>
                                                <IconButton onClick={() => handleRefreshUser(participant)} edge="end" disabled={disabledRefreshUser[participant.id] || false} >
                                                    <RefreshIcon style={{fontSize: 16}} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title='Expulsar usuario'>
                                                <IconButton onClick={() => handleKickUser(participant)} edge="end" disabled={disabledKickUser[participant.id] || false} >
                                                    <EjectIcon style={{fontSize: 16}} />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title='Reiniciar App'>
                                                <IconButton onClick={() => handleResetApp(participant)} edge="end" disabled={disabledResetButton[participant.id] || false} >
                                                    <PowerIcon style={{fontSize: 16}} />
                                                </IconButton>
                                            </Tooltip>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                )
                            }
                        </UserList>
                    </AccordionDetails>
                </Accordion>
                <RAButton
                    style={{ marginTop: '4rem', backgroundColor: 'red', color: 'white', width: '100%' }}
                    onClick={handleClick}
                    label="Finalizar Actividad"
                    alignIcon="left"
                    disabled={endActivityPressed}
                    className={endActivityPressed ? 'disabledActivityButton' : 'enabledActivityButton'}
                ><CallEndIcon /></RAButton>
                <Dialog aria-labelledby="simple-dialog-title" open={endActivityProcessing}>
                    <DialogTitle>
                        <CircularProgress style={{ marginRight: '2.5rem' }} /><span>Estamos finalizando la llamada</span>
                    </DialogTitle>
                </Dialog>
                {/* <ListButton
                    style={{ marginTop: 'auto', backgroundColor: 'red', width: '100%' }}
                    variant="contained"
                    color="primary"
                    basePath="/scheduledActivities"
                    label="Finalizar actividad"
                    icon={<CallEndIcon />}
                    onClick={() => endActivity(record, refresh)}
                >
                </ListButton> */}
            </div>
            <Dialog
                fullWidth
                open={showDialog}
                //onClose={handleCloseClick}
                scroll="body"
            >
                <DialogTitle>Feedback de la Sesión</DialogTitle>
                <DialogContent>
                    {(interactionList && interactionList?.length > 0) ?
                        <div>
                            <label style={{ fontSize: '0.7rem' }}>Comentario</label>
                            <ReactQuill value={generalComment} onChange={(val) => onChangeGeneralComment(val)} style={{ height: '5rem' }} className='generalCommentQuill' />
                            <TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto" {...props} style={{ marginTop: '3.5rem' }} />}>
                                {interactionList.map((interaction, index) =>
                                    <Tab label={interaction?.client?.name + " " + interaction?.client?.surnames}>
                                        <div style={{ display: 'flex' }}>
                                            <label style={{ fontSize: '0.7rem', marginLeft: '2rem', alignSelf: 'flex-start' }}>Detalles
                                            </label>
                                            <label style={{ fontSize: '0.7rem', position: 'relative', left: '26rem' }}>Juicio técnico
                                            </label>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1rem' }}>
                                            <ReactQuill className="commentQuill" value={interaction?.comment} onChange={(val) => onChangeComment(val, index)} style={{ width: '47%', height: '12rem' }} />
                                            <ReactQuill className="commentQuill" value={interaction?.technicalComment} onChange={(val) => onChangeTechComm(val, index)} style={{ width: '47%', height: '12rem' }} />
                                        </div>
                                        <label style={{ fontSize: '0.7rem', alignSelf: 'flex-start' }}>Información visible por terceras partes
                                        </label>
                                        <ReactQuill label="Información visible por terceras partes" className="generalCommentQuill" value={interaction?.commentForRelative} onChange={(val) => onChangeRelativesComm(val, index)} style={{ width: '100%' }} />
                                        <div style={{ display: 'flex', marginTop: '0.5rem' }}>
                                            <Labeled label="Estado de ánimo">
                                                <StarPicker style={{ marginTop: 20 }} onChange={(val) => onStarClickMood(val, index)} value={interaction?.mood}
                                                    numberStars={6} doubleTapResets size={30} />
                                            </Labeled>
                                            <Labeled label="Feedback sesión">
                                                <StarPicker style={{ marginTop: 20, margingLeft: '3 rem' }} onChange={(val) => onStarClickFeedback(val, index)}
                                                    value={interaction?.feedbackMark} numberStars={6} doubleTapResets size={30} />
                                            </Labeled>
                                            <Labeled label="Estado físico">
                                                <StarPicker style={{ marginTop: 20, margingLeft: '3 rem' }} onChange={(val) => onStarClickHealth(val, index)}
                                                    value={interaction?.healthCondition} numberStars={6} doubleTapResets size={30} />
                                            </Labeled>
                                        </div>
                                    </Tab>
                                )}
                            </TabbedShowLayout>
                        </div> : <p>No ha habido asistentes a esta reunión</p>
                    }
                    {databaseOperationInProgress ? <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', alignContent: 'center', justifyContent: 'center' }}><CircularProgress /> Por favor espere</Box> : null}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="primary"
                        label="Cancelar"
                        onClick={handleCancelClick}
                    >
                        Cancelar
                    </Button>
                    <Button variant="contained" color="primary"
                        label="Guardar"
                        disabled={disableButtons}
                        onClick={handleCloseClick}
                    >
                        Guardar
                    </Button>
                </DialogActions >
            </Dialog >
        </div >
    )
}

function getParticipantName(participant) {
    if (!participant) {
      return null;
    }
    if (isNonEmptyString(participant?.alias)) {
      return participant?.alias;
    }
    if (isNonEmptyString(participant?.name)) {
      return `${participant?.name} ${participant?.surnames}`.trim();
    }
    return participant?.participantId;
}


function isNonEmptyString(value) {
    return value != null && value.trim() !== '';
}

const OptionIconRenderer = choice => {
    return (
        <span>
            <FontAwesomeIcon style={{ marginRight: 10 }} icon={["fas", choice?.record?.value]} />
            {choice?.record?.value}
        </span>
    )
};

